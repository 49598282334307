import React, { useEffect, useState } from "react"
import Layout from "components/layout/layout"
import BannerTopo from "../../components/bannerTopo"
import InfosPage from "../../components/InfosPage"
import Accordion from "../../components/accordion"
import { getBucketUrl } from '../../service'

const Faq = () => {

  const [api, setApi] = useState({});

  const init = (apiData) => {
    setApi(apiData);
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <Layout loadedCallback={init} pageTitle="Faq" pageDescription="Perguntas e respostas frequentes sobre carreiras e oportunidades de na Stone Co.">
        {api && api.constructor === Object && Object.keys(api).length !== 0 && (
          <>
            <InfosPage titlePage="Perguntas Frequentes" text={api.singletons.faq.text}/>
            <BannerTopo image={getBucketUrl(api.singletons.faq.image.path)} />
            <Accordion type="faq" faq={api.collections.faq.map(item => { return { title: item.question, text: item.answer }})} />
          </>
        )}
      </Layout>
    </div>
  )
}

export default Faq;
