import React, { useEffect, useState } from "react"

const Accordion = props => {
  const [infosFiltered, setInfosFiltered] = useState([])
  const [setIndex, setIndexState] = useState()

  useEffect(() => {
    setInfosFiltered(props.faq)
  }, [])

  return (
    <div className="u-wrapper">
      <div className="c-accordion">
        {infosFiltered.map((item, index) => (
          <div
            className={`c-accordion__item ${
              setIndex !== undefined && setIndex === index ? "is-active" : ""
            }`}
            key={index}
          >
            <h3
              className="c-accordion__header"
              onClick={() => {
                setIndexState(index)
              }}
            >{item.title}</h3>
            <div className="c-accordion__content">
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Accordion
